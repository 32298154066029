import { useEffect, useState } from "react";
import background from "./WallpaperClockBg.png";
import whiteLogo from "./devarpLogoWhite.png";
import "./App.css";

import {
  Box,
  CssBaseline,
  GlobalStyles,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";
import { MemoizedFields } from "./Fields";


type JsonData = {
  startDate: string;
  projectTitle: string;
  finalDate: string;
};

function App() {
  const dataURL =
    "https://raw.githubusercontent.com/Sayeimehtap/json-data-for-clock/main/data.json";

  // ** States
  const [launchTime, setLaunchTime] = useState(() => new Date());
  const [workingTime, setWorkingTime] = useState<number>(0);

  const [localTime, setLocalTime] = useState(`00:00`);
  const [localSecond, setLocalSecond] = useState(`00`);

  const [localDateDayString, setLocalDateDayString] = useState("Pazartesi");
  const [localDateMonthString, setLocalDateMonthString] = useState("Haziran");
  const [localDateString, setLocalDateString] = useState("01.01.2024");

  const [jsonDataContent, setJsonDataContent] = useState<JsonData[]>([
    {
      startDate: "",
      projectTitle: "",
      finalDate: "",
    },
  ]);

  const getDatas = () => {
    fetch(dataURL)
    .then((response) => response.json())
    .then((jsonData) => {
      console.log(jsonData);
      setJsonDataContent(jsonData);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    getDatas();
    const interval = setInterval(() => {
      getDatas();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const refreshDate = new Date();
      const time = refreshDate.toLocaleTimeString();

      let dayNumber = refreshDate.getDate();
      let dayNumberString = dayNumber.toString();
      if (dayNumber < 10) {
        dayNumberString = `0${dayNumber}`;
      }
      let day = refreshDate.toLocaleDateString("tr-TR", { weekday: "long" });
      let monthNumber = refreshDate.getMonth() + 1;
      let monthNumberString = monthNumber.toString();
      if (monthNumber < 10) {
        monthNumberString = `0${monthNumber}`;
      }
      let month = refreshDate.toLocaleDateString("tr-TR", { month: "long" });

      const timeArray = time.split(":");

      if (Number(timeArray[0]) === 0) {
        setWorkingTime(0);
      } else if (18 > Number(timeArray[0]) && Number(timeArray[0]) > 8) {
        setWorkingTime(
          100 -
            ((18 * 3600 -
              (Number(timeArray[0]) * 3600 +
                Number(timeArray[1]) * 60 +
                Number(timeArray[2]))) /
              (10 * 3600)) *
              100
        );
      } else {
        setWorkingTime(100);
      }

      setLocalTime(`${timeArray[0]}:${timeArray[1]}`);
      setLocalSecond(`${timeArray[2]}`);
      setLocalDateDayString(day);
      setLocalDateMonthString(month);
      setLocalDateString(
        `${dayNumberString}.${monthNumberString}.${
          refreshDate.getUTCFullYear() - 2000
        }`
      );
    }, 100);

    return () => clearInterval(interval);
  }, [launchTime]);

  const defaultTheme = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorTransparent: {
            background: "transparent !important",
            boxShadow: "none",
          },
        },
      },
    },
    typography: {
      fontFamily: [
        "Play",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{ display: "block", maxWidth: "100vw !important" }}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        <Box
          component={"main"}
          sx={{
            width: "100vw !important",
            height: "100vh !important",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
              paddingbottom: "50px",

              paddingLeft: "180px",
              height: "100vh !important",
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 35,
                top: 25,
              }}
            >
              <img className="white_logo" src={whiteLogo} width="auto" height={180} alt="white_logo" />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  position: "relative",
                  width: 600,
                  height: 600,
                  minWidth: 600,
                  mr: 5,
                }}
              >
                <div
                  className="clock clock-for-border"
                  style={{ marginTop: 0, marginLeft: 15 }}
                >
                  <div className="line_wrapper">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  <span
                    className="clock_hour animate"
                    style={{
                      animationDelay: `-${
                        launchTime.getHours() * 3600 +
                        launchTime.getMinutes() * 60 +
                        launchTime.getSeconds()
                      }s`,
                    }}
                  ></span>
                  <span
                    className="clock_minutes animate"
                    style={{
                      animationDelay: `-${
                        launchTime.getMinutes() * 60 + launchTime.getSeconds()
                      }s`,
                    }}
                  ></span>
                  <span
                    className="clock_seconds animate"
                    style={{ animationDelay: `-${launchTime.getSeconds()}s` }}
                  ></span>
                  <span className="clock_rounder"></span>
                </div>
              </Box>
              <Box
                sx={{
                  width: 945,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  marginRight: 5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    mt: 15,
                  }}
                >
                  <Typography
                    className="local_time"
                    sx={{
                      fontFamily: '"Alata", sans-serif',
                      fontSize: "295px",
                      lineHeight: 1.3,
                      fontWeight: 600,
                      mt: 7,
                      color: "#FBFCFC",
                      textShadow:
                        "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                    }}
                  >
                    {localTime}
                  </Typography>
                  <Typography
                    className="local_time_second"
                    sx={{
                      fontFamily: '"Alata", sans-serif',
                      fontSize: "200px",
                      fontWeight: 700,
                      ml: 1,
                      maxWidth: 250,
                      minWidth: 250,
                      color: "#E51920",
                      textShadow:
                        "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                    }}
                  >
                    {localSecond}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "113px",
                        pr: 3,
                        fontFamily: '"Noto Sans Display", sans-serif',
                        color: "#FBFCFC",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      {localDateDayString},
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      pr: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 300,
                        fontSize: "63px",
                        fontFamily: '"Noto Sans Display", sans-serif',
                        color: "#FBFCFC",
                        lineHeight: 0.3,
                        textAlign: "center",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      {localDateMonthString.toLocaleLowerCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "113px",
                        fontFamily: '"Noto Sans Display", sans-serif',
                        textAlign: "center",
                        color: "#FBFCFC",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      {localDateString}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                pl: 4,
                pr: 3,
              }}
            >
              <Grid container>
                <Grid container item xs={12}>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontFamily: '"Noto Sans Display", sans-serif',
                        fontSize: "50px",
                        fontWeight: 600,
                        lineHeight: 1.2,
                        textAlign: "start",
                        color: "#B3B6B7",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      Başlangıç
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      sx={{
                        fontFamily: '"Noto Sans Display", sans-serif',
                        fontSize: "50px",
                        fontWeight: 600,
                        lineHeight: 1,
                        ml: 7,
                        textAlign: "start",
                        color: "#B3B6B7",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      Proje
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontFamily: '"Noto Sans Display", sans-serif',
                        fontSize: "50px",
                        fontWeight: 600,
                        lineHeight: 1,
                        textAlign: "end",
                        color: "#B3B6B7",
                        textShadow:
                          "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
                      }}
                    >
                      Kalan Süre
                    </Typography>
                  </Grid>
                </Grid>
                {jsonDataContent.map((item, index) => (
                  <MemoizedFields
                    key={index}
                    startDate={item.startDate}
                    projectTitle={item.projectTitle}
                    finalDate={item.finalDate}
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
