import { Box, Grid, Typography } from "@mui/material";
import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";

type JsonData = {
  startDate: string;
  projectTitle: string;
  finalDate: string;
};


export function Fields({ startDate, projectTitle, finalDate } : JsonData) {

    const [remainingDate, setRemainingDate] = useState<string>('??')
    const [remainingRatio, setRemainingRatio] = useState<number>(0)


    const convertTextToTime = (pText: string) => {
      const splittedArray = pText.split("/");
      return `${splittedArray[2]}-${splittedArray[1]}-${splittedArray[0]}`
    } 

    useEffect(() => {
        const refreshDate = new Date();

        const convertedStartDate = convertTextToTime(startDate);
        const convertedFinalDate = convertTextToTime(finalDate);

        console.log(convertedStartDate, convertedFinalDate)

        const remaningDateDay =
          Number(
            (
              (new Date(convertedFinalDate).getTime() - refreshDate.getTime()) /
              (1000 * 3600 * 24)
            ).toFixed(0)
          ) % 7;
        const remaningDateWeek =
          (Number(
            (
              (new Date(convertedFinalDate).getTime() - refreshDate.getTime()) /
              (1000 * 3600 * 24)
            ).toFixed(0)
          ) -
            (Number(
              (
                (new Date(convertedFinalDate).getTime() - refreshDate.getTime()) /
                (1000 * 3600 * 24)
              ).toFixed(0)
            ) %
              7)) /
          7;
        const remainingRatio =
          100 -
          ((new Date(convertedFinalDate).getTime() - refreshDate.getTime()) /
            (new Date(convertedFinalDate).getTime() - new Date(convertedStartDate).getTime())) *
            100;
    
        setRemainingDate(`${
            remaningDateWeek > 0 ? remaningDateWeek + " Hafta" : ""
          } ${remaningDateDay > 0 ? remaningDateDay + " Gün" : "??"}`)
        setRemainingRatio(remainingRatio)

    }, [startDate, finalDate, projectTitle])


    return (
        <Grid container item xs={12}>
        <Grid item xs={2}>
          <Typography
            sx={{
              fontFamily: '"Noto Sans Display", sans-serif',
              fontSize: "65px",
              fontWeight: 500,
              lineHeight: 1.2,
              textAlign: "start",
              color: "#FBFCFC",
              textShadow:
                "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
            }}
          >
            {startDate}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
          noWrap
            sx={{
              fontFamily: '"Noto Sans Display", sans-serif',
              fontSize: "65px",
              fontWeight: 500,
              lineHeight: 1.2,
              ml: 7,
              textAlign: "start",
              color: "#FBFCFC",
              textShadow:
                "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
            }}
          >
            {projectTitle}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              fontFamily: '"Noto Sans Display", sans-serif',
              fontSize: "65px",
              fontWeight: 500,
              lineHeight: 1.2,
              textAlign: "end",
              color: "#FBFCFC",
              textShadow:
                "2px 7px 5px rgba(0,0,0,0.3) 0px -4px 10px rgba(255,255,255,0.3)",
            }}
          >
            {
              remainingDate
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Line
              style={{
                width: "100%",
                maxHeight: 5,
              }}
              percent={remainingRatio}
              strokeWidth={1}
              trailWidth={1}
              strokeLinecap="square"
              strokeColor={"#F44336"}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  
  export const MemoizedFields = React.memo(Fields);